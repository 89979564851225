<!--
 * @Author: SongYijie
 * @Date: 2020-05-11 14:53:44
 * @LastEditors: SongYijie
-->
<template>
  <div class='wrap'>
    <mt-header title="发票管理">
      <router-link to="" slot="left">
        <mt-button icon="back" @click.native="$router.back(-1)"></mt-button>
      </router-link>
      <img slot="right" width="21" src="@assets/images/individualBusiness/home.png" alt="home" @click="handleGotoApply">
    </mt-header>
    <div class="banner">
      <img src="@assets/images/individualBusiness/banner2.png" alt="banner" />
    </div>
    <div class="invoice" v-if="this.list.length > 0">
      <CollapseItem v-for="(item, index) in this.list" :key="index" :quarterInfo="item" />
    </div>
    <p v-else class="empty">
      暂无发票信息
    </p>
  </div>
  
</template>

<script>
import CollapseItem from '@components/collapse-item';
import { getInbuInvoiceList } from '@api/individualBusiness';
export default {
  name: "invoice",
  components: {
    CollapseItem
  },
  data() {
    return {
      list: []
    };
  },
  mounted() {
    getInbuInvoiceList().then(res => {
      if (res.code === 200) {
        this.list = res.data || [];
      } else {
        Toast(res.message);
      }
    })
  },
  beforeDestroy() {},
  computed: {},
  methods: {
    handleGotoApply() {
      this.$router.push('/individual-business/invoice-apply');
    }
  }
};
</script>

<style lang="less" scoped>
.wrap {
  min-height: 100vh;
  background-color: #f0f2f5;
}
.banner {
  padding: 2vw 4.8vw 0;
  width: 100vw;
  object-fit: cover;
  box-sizing: border-box;
  background-color: #f0f2f5;
}
.invoice {
  padding: 3.4667vw 4.8vw;
}
.empty {
  text-align: center;
  margin: 24vw 0;
  font-size: 4.9333vw;
  font-weight: bold;
  color: rgba(51,51,51,1);
}
</style>
<!--
 * @Author: SongYijie
 * @Date: 2020-05-11 14:53:44
 * @LastEditors: SongYijie
-->
<template>
  <div class="quarter-item">
    <div class="title" @click="handleShowDetail">
      <p class="info">
        <span>{{`${quarterInfo.yearDate}年第${quarterInfo.quarter}季度`}}</span>
        <span>{{`￥${quarterInfo.totalAmt || 0}` }}</span>
      </p>
      <div class="triangle">
        <svg viewBox="64 64 896 896" focusable="false" class="" data-icon="right" width="1em" height="1em" fill="currentColor" aria-hidden="true" :style="{transform: isMore ? 'rotate(-90deg)' : 'rotate(90deg)'}"><path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"></path></svg>
      </div>
    </div>
    <transition name="fade">
      <div class="detail" v-if="isMore">
        <p @click="handleDetail(item.selfInfoInvoiceDetailId)" v-for="(item, index) in quarterInfo.list" :key="index">
          <span>{{`￥${item.amt || 0}`}}</span>
          <span class="company-name">{{`${item.providerName}`}}</span>
        </p>
    </div>
    </transition>
  </div>
</template>

<script>

export default {
  name: "collapse-item",
  props: ["quarterInfo"],
  data() {
    return {
      isMore: false
    };
  },
  mounted() {
  },
  beforeDestroy() {},
  computed: {},
  methods: {
    handleShowDetail() {
      this.isMore = !this.isMore
    },
    handleDetail(id) {
      this.$router.push(`/individual-business/invoice-detail/${id}`)
    }
  }
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  white-space: nowrap;
}
.quarter-item {
  margin-bottom: 3.2vw;
}
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 4.8vw;
  height: 20vw;
  line-height: 12vw;
  padding-left: 6.1333vw;
  color: rgba(0,0,0,.75);
  border-radius: 3.4667vw;
  box-shadow: 0.8vw 0.8vw 2vw 0.9333vw rgba(0, 0, 0, 0.1);
  background-color: #fafafa;
}
.info {
  width: 100%;
  font-size: 5.6vw;
  font-weight: bold;
  color: rgba(35,24,21,1);
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  padding-right: 2.4vw;
}
.triangle {
  width: 12vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(to bottom right, #2ba2f9, #47e2f8);
  border-top-right-radius: 3.4667vw;
  border-bottom-right-radius: 3.4667vw;
}
svg {
  transition: all .3s ease;
  color: #ffffff;
}
.detail {
  padding-top: 0.8vw;
  width: 100%;
  color: rgba(0,0,0,.65);
  text-align: right;
  overflow: hidden;
  border-top: 0;
  p {
    height: 9.3333vw;
    background-color: #f4f4f4;
    line-height: 9.3333vw;
    font-size: 3.7333vw;
    font-weight: 500;
    color: rgba(35,24,21,1);
    padding: 0 4.8vw 0 12vw;
    display: flex;
    justify-content: space-between;
    border-radius: 1.0667vw;

    .company-name {
      &::after {
        content: '>';
        margin-left: 6.6667vw;
      }
    }
    
    &:nth-of-type(even) {
      background-color: #f0f2f5;
      border: 0.1333vw solid #e8e8e8;
    }
  }
}
.fade-enter-active, .fade-leave-active {
  transition: all .3s ease;
}
.fade-enter, .fade-leave-to  {
  height: 0;
  padding: 0;
}
</style>
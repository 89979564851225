/*
 * @Author: SongYijie
 * @Date: 2020-03-16 17:37:46
 * @LastEditors: SongYijie
*/
import service, { serviceFormData } from '@utils/request';

// 获取个体户列表
const getUserSelfEEList = () => {
  return service({
    url: '/front/selfEE/viewUserSelfEEList',
    method: 'post'
  })
}

// 获取用户信息
const getInBuBaseInfo = () => {
  return service({
    url: '/front/selfEE/registerSelfEE',
    method: 'post'
  })
}

// 基础信息确认银行卡信息
const checkidNum = data => {
  return service({
    url: '/front/selfEE/registerBaseInfo',
    method: 'post',
    data
  })
}

// 获取活体行为
const getFaceVideoAct = () => {
  return service({
    url: '/front/selfEE/faceVedio',
    method: 'post'
  })
}

// 活体人脸检测
const getFaceVideoCheck = (file, behavior) => {
  return serviceFormData({
    url: `/front/selfEE/faceVedioCheck?behavior=${behavior}`,
    method: 'post',
    data: file
  })
}

// 上传人脸图片
const uploadFaceImage = data => {
  return serviceFormData({
    url: '/front/selfEE/uploadFacePic',
    method: 'post',
    data
  })
}

// 获取确认信息
const getConfirmInfo = () => {
  return service({
    url: '/front/selfEE/viewUserSelfEE',
    method: 'post'
  })
}

// 确认信息
const confirmInfo = () => {
  return service({
    url: '/front/selfEE/registerSelfEEConfirm',
    method: 'post'
  })
}

// 上传签名图片
const uploadSignImage = data => {
  return service({
    url: '/front/selfEE/uploadContractFile',
    method: 'post',
    data
  })
}

// 用户税务登记与否
const operTaxProve = data => {
  return service({
    url: '/front/selfEE/taxRegState',
    method: 'post',
    data
  })
}

// 人脸照片识别
const facePicCheck = data => {
  return serviceFormData({
    url: '/front/selfEE/facePicCheck',
    method: 'post',
    data
  })
}

// 个体户发票明细列表
const getInbuInvoiceList = () => {
  return service({
    url: '/front/selfEeInfoInvoice/getSelfEeInfoInvoiceApplyList',
    method: 'post'
  })
}

// 个体户发票明细列表-详情
const getInbuInvoiceDetail = data => {
  return service({
    url: '/front/selfEeInfoInvoice/getByInvoiceaDetailByApplyIdList',
    method: 'post',
    data
  })
}

// 个体户发票明细列表
const getAddInvoiceApply = data => {
  return service({
    url: '/front/selfEeInfoInvoice/addInvoice',
    method: 'post',
    data
  })
}

// 企业关键词搜索
const searchCompany= name => {
  return service({
    url: '/front/searchCompany/searchCompanyInfo',
    method: 'post',
    data: {
      companyName: name
    }
  })
}

// 企业工商详情查询
const getCompanyDetail = name => {
  return service({
    url: '/front/searchCompany/getCompanyDetails',
    method: 'post',
    data: {
      companyName: name
    }
  })
}

// 标准个体户开票申请列表
const getInvoiceApplyList = data => {
  return service({
    url: '/front/selfEeInfoInvoice/getInvoiceByUserNoList',
    method: 'post',
    data
  })
}

// 新增时查询个体户信息
const getSelfEEInfo = () => {
  return service({
    url: '/front/selfEeInfoInvoice/getselfEeInfo',
    method: 'post',
    data: {}
  })
}

// 申请单详情
const getinvoiceApplyDetail = id => {
  return service({
    url: `/front/selfEeInfoInvoice/getInvoiceApplyById/${id}`,
    method: 'get'
  })
}

// 取消申请单详情
const getInvoiceApplyCancel = id => {
  return service({
    url: '/front/selfEeInfoInvoice/upInvoiceState',
    method: 'post',
    data: {
      id
    }
  })
}

// 填写电子税务局密码
const getElecTaxPassword = data => {
  return service({
    url: '/front/selfEE/taxPassword',
    method: 'post',
    data
  })
}

// 个体户申请注销
const getCancelInbu = () => {
  return service({
    url: '/front/selfEE/selfEeLogOff',
    method: 'post',
    data: {}
  })
}

// 个体户注销详情
const getCancelInbuDetail = () => {
  return service({
    url: '/front/selfEE/logOffBase',
    method: 'post',
    data: {}
  })
}

// 获取行业额类型
const getBussinessType = () => {
  return service({
    url: '/front/selfEE/industry',
    method: 'post',
    data: {}
  })
}

export {
  getInBuBaseInfo,
  checkidNum,
  uploadFaceImage,
  getConfirmInfo,
  confirmInfo,
  uploadSignImage,
  operTaxProve,
  getFaceVideoAct,
  getFaceVideoCheck,
  facePicCheck,
  getInbuInvoiceList,
  getInbuInvoiceDetail,
  getAddInvoiceApply,
  searchCompany,
  getCompanyDetail,
  getInvoiceApplyList,
  getSelfEEInfo,
  getinvoiceApplyDetail,
  getInvoiceApplyCancel,
  getElecTaxPassword,
  getCancelInbu,
  getCancelInbuDetail,
  getBussinessType,
  getUserSelfEEList
}